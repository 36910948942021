/* eslint jsx-a11y/anchor-is-valid: off */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { last, isEqual } from 'underscore';
import { Modal, RenderInBody, Icon } from 'bv-components';
import { useLockBodyScroll } from 'bv-hooks';

const ModalNav = ({
  tree,
  initialPath,
  initialIndex,
  closeModalNav,
}) => {
  const history = useHistory();
  const scrollableRef = useRef();
  const [currentPath, setPath] = useState(initialPath);

  useLockBodyScroll(scrollableRef);

  const currentParent = currentPath
    .slice(0, currentPath.length - 1)
    .reduce((acc, pathIndex) => acc[pathIndex].children, tree)[last(currentPath)];

  const navigateBack = () => {
    if (currentPath.length > 0) {
      setPath(currentPath.slice(0, currentPath.length - 1));
    }
  };

  const navigateForward = (itemIndex) => {
    if (
      !currentParent
       || (currentParent.children && currentParent.children[itemIndex].children.length > 0)
    ) {
      setPath([...currentPath, itemIndex]);
    }
  };

  const currentItems = currentParent ? currentParent.children : tree;

  const selected = (itemIndex) => itemIndex === initialIndex && isEqual(initialPath, currentPath);

  const navigateTo = (href) => {
    history.push(href);
    closeModalNav();
  };

  return (
    <RenderInBody>
      <Modal
        info
        iconDotsClosable
        icon={false}
        className="list-modal"
        onCloseClick={closeModalNav}
      >
        <div>
          <div className="list-modal-top-title-wrapper">
            {
              currentParent && currentParent.title && (
                <span onClick={navigateBack} className="list-modal-top-title">
                  <Icon className="is-arrow-left" />
                  {`${currentParent.title}`}
                </span>
              )
            }
          </div>
          <ul className="list-modal-items" ref={scrollableRef}>
            {
              currentItems.map((item, itemIndex) => (
                item.href
                  ? (<li className={`list-modal-item ${selected(itemIndex) ? 'active' : ''}`}><a onClick={() => navigateTo(item.href)} data-react>{item.title}</a></li>)
                  : (<li className="list-modal-item" onClick={() => navigateForward(itemIndex)}>{item.title}</li>)
              ))
            }
          </ul>
        </div>
      </Modal>
    </RenderInBody>
  );
};

ModalNav.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
  initialPath: PropTypes.number.isRequired,
  initialIndex: PropTypes.number.isRequired,
  closeModalNav: PropTypes.func.isRequired,
};

export default ModalNav;
