import {
  FETCH_EVENTS_INIT,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAILED,
  SET_MARKETS_INIT,
  SET_MARKETS_SUCCESS,
  SET_ACTIVE_MARKET_INDEX_SUCCESS,
  SET_COMPETITION_TITLE,
  SET_CONFIG,
  RESET_STATE_SUCCESS,
} from './action_types';

const initialState = {
  loading: true,
  failed: false,
  loadingMarkets: true,
  competitionTitle: undefined,
  markets: [],
  activeMarketIndex: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_EVENTS_INIT:
      return {
        ...state,
        loading: true,
      };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case FETCH_EVENTS_FAILED:
      return {
        ...state,
        loading: false,
        failed: true,
      };

    case SET_MARKETS_INIT:
      return {
        ...state,
        loadingMarkets: true,
      };

    case SET_MARKETS_SUCCESS: {
      const { markets, activeMarketIndex } = action;
      return {
        ...state,
        loadingMarkets: false,
        markets,
        activeMarketIndex,
      };
    }

    case SET_ACTIVE_MARKET_INDEX_SUCCESS: {
      const { activeMarketIndex } = action;
      return {
        ...state,
        activeMarketIndex,
      };
    }

    case SET_COMPETITION_TITLE: {
      const { competitionTitle } = action;

      return {
        ...state,
        competitionTitle,
      };
    }

    case SET_CONFIG: {
      const { config } = action;

      return {
        ...state,
        config,
      };
    }

    case RESET_STATE_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
