import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Spinner } from 'bv-components';
import AppView from './app_view';

const AppContainer = ({
  fetchEvents, hasEvents, loading, resetState,
}) => {
  const { sportId, meetingId } = useParams();

  useEffect(() => {
    fetchEvents({
      sportEventPathId: sportId,
      eventPathId: meetingId,
    });
    return resetState;
  }, [meetingId]);

  if (loading) return <Spinner />;

  return (
    <AppView sportId={parseInt(sportId, 10)} hasEvents={hasEvents} />
  );
};

AppContainer.propTypes = {
  fetchEvents: PropTypes.func.isRequired,
  hasEvents: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  resetState: PropTypes.func.isRequired,
};

export default AppContainer;
