import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ScoreboardContext } from 'bv-contexts';
import MarketsSelector from 'SportSections/competitions/components/markets_selector';
import Navigation from 'SportSections/competitions/components/navigation';
import OverviewWrapper from 'SportSections/competitions/components/overview_wrapper';
import Scoreboard from 'SportSections/competitions/components/scoreboard';
import Title from 'SportSections/competitions/components/title';
import useCompetitionScoreboard from 'SportSections/competitions/hooks/use_competition_scoreboard';

const HORSE_RACING_EVENT_PATH_ID = 364;

const AppView = ({ hasEvents, sportId }) => {
  const [headerRef, setHeaderRef] = useState(null);
  const { showScoreboard, scoreboardConfiguration } = useCompetitionScoreboard();

  const value = useMemo(() => ({
    headerRef,
    setHeaderRef,
    scoreboardConfiguration,
    showEventNavigator: false,
  }), [headerRef, scoreboardConfiguration]);

  return (
    <ScoreboardContext.Provider value={value}>
      <div className={`${showScoreboard && hasEvents ? 'site-header-wrapper' : ''}`}>
        <Title />
        { sportId !== HORSE_RACING_EVENT_PATH_ID && <Navigation />}
      </div>
      {hasEvents && (
        <>
          {showScoreboard && <Scoreboard />}
          <MarketsSelector />
        </>
      )}
      <OverviewWrapper />
    </ScoreboardContext.Provider>
  );
};

AppView.propTypes = {
  hasEvents: PropTypes.bool.isRequired,
  sportId: PropTypes.string.isRequired,
};

export default AppView;
