import { upsertEvents } from 'sportsbook-events-duck';
import { Redux as OverviewRedux } from 'sportsbook-overview';
import { Api as CompetitionsApi } from 'sportsbook-competitions';
import { Services as SportsTreeServices } from 'sportsbook-sports-tree';
import { setMetaInfo } from 'bv-services/seo';
import setMarkets from './set_markets';
import {
  fetchEventsInit,
  fetchEventsSuccess,
  fetchEventsFailed,
  setCompetitionTitle,
  setConfig,
} from '../action_creators';
import getActiveMarket from '../selectors/get_active_market';

const {
  insertEvents,
  setCards,
  setEventFilters,
  setGroupsByCard,
} = OverviewRedux.actionCreators;

export default ({ sportEventPathId, eventPathId }) => (dispatch, getState) => {
  dispatch(fetchEventsInit());

  CompetitionsApi.fetchCompetition({ eventPathId }).then((sportsTree) => {
    const {
      tree, config: { marketsConfig, ...otherConfig }, competitionTitle, sportName,
    } = sportsTree;
    const { events, cards, groupsByCard } = SportsTreeServices.normalizeSportsTree(tree);

    dispatch(setCompetitionTitle(competitionTitle));
    dispatch(setConfig(otherConfig));

    dispatch(upsertEvents(Object.values(events)));
    dispatch(insertEvents(Object.keys(events)));
    dispatch(setGroupsByCard({ groupsByCard }));
    dispatch(setCards({ cards }));

    dispatch(setMarkets({ marketsConfig }));
    dispatch(setEventFilters({
      eventLabels: getActiveMarket(getState()).eventLabels,
      expanded: otherConfig.expandedCards,
    }));

    dispatch(fetchEventsSuccess());

    setMetaInfo(() => ({
      id: sportEventPathId,
      type: 'meeting',
      data: { meeting_name: competitionTitle, sport_name: sportName },
    }));
  }).catch(() => {
    dispatch(fetchEventsFailed());
  });
};
