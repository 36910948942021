import { useParams } from 'react-router';
import { isEmpty } from 'underscore';
import { useSportTab } from 'bv-hooks';
import { Spinner, SeoTitle } from 'bv-components';
import withLazyLoad from 'bv-lazy-load';
import { Robots } from 'bv-metas';
import { listItemRetriever } from './services';
import Nav from './components/nav';
import Accas from './components/accas';

const ErrorPage = withLazyLoad(<Spinner />)('errorPage');

const CustomListApp = () => {
  const {
    sportId,
    componentId,
    groupIndex,
    itemIndex,
  } = useParams();

  // Load the current Custom List Sport Tab info
  const [customListTab, loadingCustomListTab] = useSportTab(
    componentId,
    sportId,
    [sportId, componentId],
    { useEventPathId: true },
  );

  if (loadingCustomListTab) return <Spinner />;
  if (isEmpty(customListTab)) return <ErrorPage showContentLayout />;

  const { linkGroups } = customListTab;
  const listItem = listItemRetriever(linkGroups, groupIndex, itemIndex);

  return (
    <>
      <Robots content="noindex, nofollow" />
      <div className="custom-list-wrapper__header">
        <SeoTitle defaultHeading={listItem.description} />
        <Nav linkGroups={linkGroups} />
      </div>
      <div className="custom-list-wrapper__content">
        <Accas
          listItem={{
            ...listItem,
            componentId,
            sportEventPathId: sportId,
            linkGroupIndex: groupIndex,
            linkIndex: itemIndex,
          }}
        />
      </div>
    </>
  );
};

export default CustomListApp;
