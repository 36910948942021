import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'underscore';
import {
  fetchSportsTree as fetchSportsTreeAction,
  resetState as resetStateAction,
} from './duck';
import AccasContainer from './accas_container';

const AccasRedux = (props) => {
  // Stablish redux fetchSportsTree and resetState methods
  const dispatch = useDispatch();
  const fetchSportsTree = compose(dispatch, fetchSportsTreeAction);
  const resetState = compose(dispatch, resetStateAction);
  // --------------------------------------------

  // Get loading status from Redux state
  const loading = useSelector((state) => state.sportSectionsAccas.loadingTree);
  // --------------------------------------------

  return (
    <AccasContainer
      {...props}
      fetchSportsTree={fetchSportsTree}
      resetState={resetState}
      loading={loading}
    />
  );
};

export default AccasRedux;
