import { useSelector } from 'react-redux';
import { getFailed } from 'SportSections/competitions/redux/selectors';
import NavigationContainer from './navigation_container';

const NavigationRedux = () => {
  const fetchEventsFailed = useSelector((state) => getFailed(state));

  if (fetchEventsFailed) return null;

  return <NavigationContainer />;
};

export default NavigationRedux;
