import { useSelector } from 'react-redux';
import { getActiveMarket, getConfig, getFailed } from 'SportSections/competitions/redux/selectors';
import OverviewWrapperContainer from './overview_wrapper_container';

const OverviewWrapperRedux = () => {
  // Stablish redux selector to see if the events have been loaded already
  const fetchEventsFailed = useSelector(getFailed);
  const activeMarket = useSelector(getActiveMarket);
  const config = useSelector(getConfig);
  // --------------------------------------------

  return (
    <OverviewWrapperContainer
      fetchEventsFailed={fetchEventsFailed}
      activeMarket={activeMarket}
      config={config}
    />
  );
};

export default OverviewWrapperRedux;
