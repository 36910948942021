import PropTypes from 'prop-types';
import { Button, Carousel } from 'bv-components';

const MarketsSelectorView = ({ markets, activeMarketIndex, setActiveMarketIndex }) => {
  if (markets.length <= 1) return null;

  return (
    <Carousel className="bvs-carousel--embedded sportbook-section-tabs" scrollStep={110}>
      {
        markets.map((market, index) => (
          <Button
            active={index === activeMarketIndex}
            noClass
            className="bvs-button-chip"
            onClick={() => setActiveMarketIndex(index)}
          >
            { market.description }
          </Button>
        ))
      }
    </Carousel>
  );
};

MarketsSelectorView.propTypes = {
  markets: PropTypes.instanceOf(Array).isRequired,
  activeMarketIndex: PropTypes.number.isRequired,
  setActiveMarketIndex: PropTypes.func.isRequired,
};

export default MarketsSelectorView;
