import { useState } from 'react';
import NavigationView from './navigation_view';

const NavigationContainer = () => {
  const [displayModal, setDisplayModal] = useState(false);

  const openModal = () => setDisplayModal(true);
  const closeModal = () => setDisplayModal(false);

  return (
    <NavigationView
      displayModal={displayModal}
      openModal={openModal}
      closeModal={closeModal}
      iconId="dots-vertical"
    />
  );
};

export default NavigationContainer;
