import PropTypes from 'prop-types';
import MarketsSelectorView from './markets_selector_view';

const MarketsSelectorContainer = ({
  setActiveMarketIndex,
  markets,
  activeMarketIndex,
}) => (
  <MarketsSelectorView
    markets={markets}
    activeMarketIndex={activeMarketIndex}
    setActiveMarketIndex={setActiveMarketIndex}
  />
);

MarketsSelectorContainer.propTypes = {
  setActiveMarketIndex: PropTypes.func.isRequired,
  markets: PropTypes.instanceOf(Array).isRequired,
  activeMarketIndex: PropTypes.number.isRequired,
};

export default MarketsSelectorContainer;
