import PropTypes from 'prop-types';
import { Contexts } from 'sportsbook-overview';
import AccasRedux from './accas_redux';
import reducer from './duck';

const { addReducers } = window.reduxState;

const Accas = ({ listItem }) => {
  addReducers({
    sportSectionsAccas: reducer,
  });

  return (
    <Contexts.ConfigContext.Provider value={listItem}>
      <AccasRedux {...listItem} />
    </Contexts.ConfigContext.Provider>
  );
};

Accas.propTypes = {
  listItem: PropTypes.instanceOf(Object).isRequired,
};

export default Accas;
