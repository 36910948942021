import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { scoreboardConfigurations, sportsWithScoreboard } from 'SportSections/competitions/helpers';
import { getConfig } from 'SportSections/competitions/redux/selectors';

export default () => {
  const { showScoreboard } = useSelector(getConfig);
  const params = useParams();
  const sportId = parseInt(params.sportId, 10);

  return {
    showScoreboard: showScoreboard && sportsWithScoreboard.includes(sportId),
    scoreboardConfiguration: scoreboardConfigurations[sportId],
  };
};
