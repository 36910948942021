import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getConfig } from 'SportSections/competitions/redux/selectors';
import ModalContainer from './modal_container';

const ModalRedux = ({ closeModal }) => {
  const { sportDimension } = useSelector((state) => getConfig(state));

  return (
    <ModalContainer
      closeModal={closeModal}
      sportDimension={sportDimension}
    />
  );
};

ModalRedux.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalRedux;
