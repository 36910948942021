import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { Hooks as CompetitionsHooks } from 'sportsbook-competitions';
import ModalView from './modal_view';

// Function that will we use to traverse the tree and
// get which is the path (as an array of positions) we need to follow
// to reach the initial item
const getInitialPath = (tree) => {
  let initialPath = [];

  const searchPath = (currentTree, parentPath = []) => currentTree && (
    currentTree.forEach((element, index) => {
      const elementPath = [...parentPath, index];

      if (element.competitionUrl === document.location.pathname) {
        initialPath = elementPath;
      }

      if (element.children.length > 0) {
        searchPath(element.children, elementPath);
      }
    })
  );

  searchPath(tree);

  return initialPath;
};
// ---------------------------------------------------------

const ModalContainer = ({ closeModal, sportDimension }) => {
  const [currentPath, setCurrentPath] = useState([]);

  // Load the complete sport tree for the sport competitions
  const [sportTree, loadingSportTree] = CompetitionsHooks.useCompetitions({
    sportDimension,
  });

  const tree = loadingSportTree ? [] : sportTree;

  // Set the current path as the initial one (the path for the specific
  // compeptition we're in)
  useEffect(() => { setCurrentPath(getInitialPath(tree)); }, [tree]);

  // Get the list of elements we want to display when the modal is open,
  // this are all the siblings for the current comp or also we can think
  // about them as all the children of the current comp parent
  const currentList = currentPath.slice(0, -1).reduce(
    (partialTree, index) => partialTree[index].children,
    tree,
  );

  // Get the parent of the current competition we're displaying
  const currentParent = currentPath.slice(0, -1).reduce((
    partialTree, index, loopIndex, loopArray,
  ) => {
    if (loopIndex === loopArray.length - 1) return partialTree[index];

    return partialTree[index].children;
  }, tree);

  // TODO: Improve all so we dont load the sport tree again
  // if the modal has been opened once, it's not very important because of
  // the HTTP cache but would be better

  if (loadingSportTree) {
    return <Spinner />;
  }

  const navigateBack = () => setCurrentPath(currentPath.slice(0, -1));
  const navigateForward = (index) => setCurrentPath([...currentPath.slice(0, -1), index, 0]);

  return (
    <ModalView
      list={currentList}
      parent={currentParent}
      navigateBack={navigateBack}
      navigateForward={navigateForward}
      closeModal={closeModal}
    />
  );
};

ModalContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  sportDimension: PropTypes.string.isRequired,
};

export default ModalContainer;
