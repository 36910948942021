/* eslint no-undef: off */
import { ContentLayout } from 'bv-components';
import App from './components/app';
import reducer from './redux/reducer';

const { addReducers } = window.reduxState;

addReducers({
  'sportsbook/competitions': reducer,
});

const SportSectionsApp = () => (
  <ContentLayout>
    <App />
  </ContentLayout>
);

export default SportSectionsApp;
