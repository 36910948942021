import { useSelector } from 'react-redux';
import selectors from 'SportsbookOverview/redux/selectors';
import ScoreboardContainer from './scoreboard_container';

const ScoreboardRedux = () => {
  const [eventId] = useSelector(selectors.getEventIds);

  if (!eventId) return null;

  return <ScoreboardContainer eventId={eventId} />;
};

export default ScoreboardRedux;
