/* eslint no-mixed-operators: off */
const nullLink = {
  description: '',
};

export default (linkGroups, groupIndex, itemIndex) => {
  if (!linkGroups) return nullLink;

  let linkGroup = null;
  if (/^\d+$/.test(groupIndex) && linkGroups[groupIndex]) {
    linkGroup = linkGroups[groupIndex];
  } else {
    linkGroup = linkGroups.find((g) => g.slug === groupIndex);
  }

  if (!linkGroup) return nullLink;

  let link = null;
  if (/^\d+$/.test(itemIndex) && linkGroup.links[itemIndex]) {
    link = linkGroup.links[itemIndex];
  } else {
    link = linkGroup.links.find((l) => l.slug === itemIndex);
  }

  return link || nullLink;
};
