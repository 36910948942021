/* eslint no-unused-vars: off, max-len: off, no-confusing-arrow: off, object-curly-newline: off */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { DotsIcon } from 'bv-components';
import { useParams } from 'react-router-dom';
import ModalNav from './modal_nav';
import { treeBuilder } from '../../services';

const Nav = ({ linkGroups, match }) => {
  const { sportId, componentId, groupIndex, itemIndex } = useParams();
  const [displayModalNav, setDisplayModalNav] = useState(false);

  const showModalNav = () => setDisplayModalNav(true);
  const hideModalNav = () => setDisplayModalNav(false);

  let currentGroup = null;
  let initialPath = null;
  if (/^\d+$/.test(groupIndex) && linkGroups[groupIndex]) {
    currentGroup = linkGroups[groupIndex];
    initialPath = parseInt(groupIndex, 10);
  } else {
    currentGroup = linkGroups.find((g) => g.slug === groupIndex);
    initialPath = linkGroups.findIndex((g) => g.slug === groupIndex);
  }
  let initialIndex = 0;
  if (/^\d+$/.test(itemIndex) && currentGroup.links[itemIndex]) {
    initialIndex = parseInt(itemIndex, 10);
  } else {
    initialIndex = currentGroup.links.findIndex((l) => l.slug === itemIndex);
  }

  return (
    <>
      <DotsIcon onDotsClick={showModalNav} type="dots-icon" className="inplay-open-modal-dots" />
      {
        displayModalNav && (
          <ModalNav
            tree={treeBuilder(linkGroups, { sportId, componentId })}
            initialPath={[initialPath]}
            initialIndex={initialIndex}
            closeModalNav={hideModalNav}
          />
        )
      }
    </>
  );
};

Nav.propTypes = {
  linkGroups: PropTypes.instanceOf(Array).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default Nav;
