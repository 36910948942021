import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { Components } from 'sportsbook-overview';

const AccasContainer = ({
  componentId,
  couponType,
  expandedCards,
  eventLabels,
  fetchSportsTree,
  linkGroupIndex,
  linkIndex,
  loading,
  resetState,
  sportEventPathId,
}) => {
  useEffect(() => {
    fetchSportsTree({
      sportEventPathId,
      componentId,
      linkGroupIndex,
      linkIndex,
      expanded: expandedCards,
      eventLabels,
    });

    return resetState;
  }, [componentId, linkGroupIndex, linkIndex, couponType]);

  return loading ? <Spinner /> : <Components.App />;
};

AccasContainer.propTypes = {
  componentId: PropTypes.string.isRequired,
  couponType: PropTypes.instanceOf(Object).isRequired,
  expandedCards: PropTypes.number.isRequired,
  eventLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchSportsTree: PropTypes.func.isRequired,
  linkGroupIndex: PropTypes.string.isRequired,
  linkIndex: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  resetState: PropTypes.func.isRequired,
  sportEventPathId: PropTypes.string.isRequired,
};

export default AccasContainer;
