import { useSelector } from 'react-redux';
import { getCompetitionTitle, getFailed } from 'SportSections/competitions/redux/selectors';
import TitleView from './title_view';

const TitleRedux = () => {
  const competitionTitle = useSelector((state) => getCompetitionTitle(state));
  const fetchEventsFailed = useSelector((state) => getFailed(state));

  if (!competitionTitle || fetchEventsFailed) {
    return null;
  }

  return (
    <TitleView
      competitionTitle={competitionTitle}
    />
  );
};

export default TitleRedux;
