import PropTypes from 'prop-types';
import {
  DotsIcon, Modal, RenderInBody,
} from 'bv-components';

import { Modal as ModalContent } from './modal';

const NavigationView = ({
  displayModal,
  openModal,
  closeModal,
}) => (
  <>
    <div onClick={openModal} className="vertical-dots-area-wrapper">
      <DotsIcon type="dots-icon" />
    </div>

    {
      displayModal && (
        <RenderInBody>
          <Modal
            info
            iconDotsClosable
            icon={false}
            className="list-modal"
            onCloseClick={closeModal}
          >
            <ModalContent
              closeModal={closeModal}
            />
          </Modal>
        </RenderInBody>
      )
    }
  </>
);

NavigationView.propTypes = {
  displayModal: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default NavigationView;
