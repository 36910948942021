import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { useLockBodyScroll } from 'bv-hooks';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

const activeLinkClass = (link) => classnames('list-modal-item', {
  active: document.location.pathname === link.competitionUrl,
});

const ModalView = ({
  list,
  parent,
  navigateBack,
  navigateForward,
  closeModal,
}) => {
  const history = useHistory();
  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  // we need to provide the path without the locale
  // to history.push or we will end with something like
  // /en-gb/en-gb/....
  const navigateTo = (href) => {
    const hrefWithoutLocale = href
      .split('/')
      .slice(2)
      .join('/');

    history.push(`/${hrefWithoutLocale}`);
    closeModal();
  };

  return (
    <>
      <div>
        <div className="list-modal-top-title-wrapper">
          {
            parent && parent.description && (
              <span onClick={navigateBack} className="list-modal-top-title">
                <Icon className="is-arrow-left" />
                {parent.description}
              </span>
            )
          }
        </div>
      </div>

      <ul className="list-modal-items" ref={scrollableRef}>
        {
          list.map((element, index) => {
            const onClick = element.competitionUrl
              ? () => navigateTo(element.competitionUrl)
              : () => navigateForward(index);

            return (
              <li
                className={activeLinkClass(element)}
                onClick={onClick}
              >
                {element.description}
              </li>
            );
          })
        }
      </ul>
    </>
  );
};

ModalView.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  parent: PropTypes.instanceOf(Object).isRequired,
  navigateBack: PropTypes.func.isRequired,
  navigateForward: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalView;
