import { useContext } from 'react';
import PropTypes from 'prop-types';
import { SeoTitle } from 'bv-components';
import { ScoreboardContext } from 'bv-contexts';
import { SEOSportTitle } from 'bv-helpers/tabs';

const TitleView = ({ competitionTitle }) => {
  const { setHeaderRef } = useContext(ScoreboardContext);

  return (
    <SeoTitle
      defaultHeadingPromise={() => Promise.resolve(SEOSportTitle(competitionTitle))}
      setHeaderRef={setHeaderRef}
    />
  );
};

TitleView.propTypes = {
  competitionTitle: PropTypes.string.isRequired,
};

export default TitleView;
