import { ContentLayout } from 'bv-components';
import App from './app';

const AppLayout = () => (
  <ContentLayout>
    <div className="custom-list-wrapper">
      <App />
    </div>
  </ContentLayout>
);

export default AppLayout;
