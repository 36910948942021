import { compose } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMarketIndex as setActiveMarketIndexAction } from 'SportSections/competitions/redux/thunks';
import {
  getMarkets,
  getActiveMarketIndex,
  getFailed,
} from 'SportSections/competitions/redux/selectors';
import MarketsSelectorContainer from './markets_selector_container';

const MarketsSelectorRedux = () => {
  const dispatch = useDispatch();

  // Stablish redux setMarkets Index method
  const setActiveMarketIndex = compose(dispatch, setActiveMarketIndexAction);

  // --------------------------------------------

  const markets = useSelector((state) => getMarkets(state));
  const activeMarketIndex = useSelector((state) => getActiveMarketIndex(state));
  const fetchEventsFailed = useSelector((state) => getFailed(state));

  if (fetchEventsFailed) return null;

  return (
    <MarketsSelectorContainer
      setActiveMarketIndex={setActiveMarketIndex}
      markets={markets}
      activeMarketIndex={activeMarketIndex}
    />
  );
};

export default MarketsSelectorRedux;
