import { t } from 'bv-i18n';

const FetchFailed = () => (
  <div className="no-events-message">
    <div className="no-events-message__icon" />
    <div className="no-events-message__text">
      <p>{t('javascript.no_events')}</p>
    </div>
  </div>
);

export default FetchFailed;
