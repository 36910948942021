import { Redux as OverviewRedux } from 'sportsbook-overview';
import {
  setMarketsInit,
  setMarketsSuccess,
} from 'SportSections/competitions/redux/action_creators';

const specialsMarket = {
  periodDimension: { label: 'outright', value: 'outright' },
  marketTypeDimension: { label: 'special', value: 'special' },
  couponHeaders: [],
  couponType: { label: 'template_c', value: 'template_c' },
};

export default ({ marketsConfig }) => (dispatch, getState) => {
  const state = getState();
  const eventTypes = OverviewRedux.selectors.getEventsTypes(state);
  const eventDimensions = OverviewRedux.selectors.getEventsDimensions(state);

  dispatch(setMarketsInit());

  // Hack, whenever we get events that contains the dimension SPECIALS
  // we're gonna hardcode the markets to fetch
  const markets = eventDimensions.some((dimension) => dimension.SPECIALS)
    ? [specialsMarket]
    : marketsConfig[`EVENT_TYPE_SEGREGATION_MATCHES${eventTypes.includes('GAME') ? '' : '_OUTRIGHTS'}`];

  dispatch(setMarketsSuccess({ markets, activeMarketIndex: 0 }));
};
