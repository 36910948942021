import { upsertEvents } from 'sportsbook-events-duck';
import { Redux as OverviewRedux } from 'sportsbook-overview';
import { Services as SportsTreeServices } from 'sportsbook-sports-tree';
import { fetchCustomList } from '../../api';

const FETCH_SPORTS_TREE_INIT = 'accas/FETCH_SPORTS_TREE';
const FETCH_SPORTS_TREE_SUCCESS = 'accas/FETCH_SPORTS_TREE_SUCCESS';
const RESET_STATE_SUCCESS = 'accas/RESET_STATE_SUCCESS';

const fetchSportsTreeInit = () => ({
  type: FETCH_SPORTS_TREE_INIT,
});

const fetchSportsTreeSuccess = () => ({
  type: FETCH_SPORTS_TREE_SUCCESS,
});

export const fetchSportsTree = ({
  sportEventPathId,
  componentId,
  linkGroupIndex,
  linkIndex,
  expanded,
  eventLabels,
}) => (dispatch) => {
  dispatch(fetchSportsTreeInit());

  fetchCustomList({
    sportEventPathId,
    componentId,
    linkGroupIndex,
    linkIndex,
  }).then((sportsTree) => {
    const { events, cards, groupsByCard } = SportsTreeServices.normalizeSportsTree(sportsTree);

    dispatch(upsertEvents(Object.values(events)));
    dispatch(OverviewRedux.actionCreators.insertEvents(Object.keys(events)));
    dispatch(OverviewRedux.actionCreators.setGroupsByCard({ groupsByCard }));
    dispatch(OverviewRedux.actionCreators.setCards({ cards }));
    dispatch(OverviewRedux.actionCreators.setEventFilters({ eventLabels, expanded }));

    dispatch(fetchSportsTreeSuccess());
  });
};

export const resetStateSuccess = () => ({
  type: RESET_STATE_SUCCESS,
});

export const resetState = () => (dispatch) => {
  dispatch(OverviewRedux.thunks.resetState());
  dispatch(resetStateSuccess());
};
// --------------------------------------------------------------------

const initialState = {
  // TODO: This is not being used in the components
  // we can use it to display a loading screen
  loadingTree: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    // TODO: Implement loading screen
    case FETCH_SPORTS_TREE_INIT:
      return {
        ...state,
        loadingTree: true,
      };

    case FETCH_SPORTS_TREE_SUCCESS:
      return {
        ...state,
        loadingTree: false,
      };

    case RESET_STATE_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
