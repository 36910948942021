import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { Contexts, Components } from 'sportsbook-overview';
import FetchFailed from './fetch_failed';

const OverviewWrapperContainer = ({ activeMarket, fetchEventsFailed, config }) => {
  const contextValue = useMemo(
    () => activeMarket && ({
      couponType: activeMarket.couponType,
      marketTypeDimension: activeMarket.marketTypeDimension,
      periodDimension: activeMarket.periodDimension,
      couponHeaders: activeMarket.couponHeaders,
      couponOutcomeKeys: activeMarket.couponOutcomeKeys,
      eventLabels: activeMarket.eventLabels,
      ignoreOutcomeKeys: activeMarket.ignoreOutcomeKeys,
      showAllEventMarkets: activeMarket.showAllEventMarkets,
      showMarketDescription: activeMarket.showMarketDescription,
      sorting: config.sorting,
      hideGroupTitle: config.hideGroupTitle,
      linkType: config.linkType,
      eventsPerComp: config.eventsPerCompetition,
      expandedCards: config.expandedCards,
      showScoreboard: config.showScoreboard,
    }),
    [activeMarket, config],
  );

  if (fetchEventsFailed) return <FetchFailed />;
  if (!activeMarket) return <Spinner />;

  return (
    <Contexts.ConfigContext.Provider value={contextValue}>
      <Components.App />
    </Contexts.ConfigContext.Provider>
  );
};

OverviewWrapperContainer.propTypes = {
  activeMarket: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  fetchEventsFailed: PropTypes.bool.isRequired,
};

export default OverviewWrapperContainer;
