import { compose, isEmpty } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { Redux as OverviewRedux } from 'sportsbook-overview';
import {
  fetchEvents as fetchEventsAction,
  resetState as resetStateAction,
} from 'SportSections/competitions/redux/thunks';
import { getLoading } from 'SportSections/competitions/redux/selectors';
import AppContainer from './app_container';

const AppRedux = () => {
  const dispatch = useDispatch();
  const fetchEvents = compose(dispatch, fetchEventsAction);
  const resetState = compose(dispatch, resetStateAction);
  const loading = useSelector(getLoading);
  const events = useSelector(OverviewRedux.selectors.getEvents);

  return (
    <AppContainer
      loading={loading}
      fetchEvents={fetchEvents}
      resetState={resetState}
      hasEvents={!isEmpty(events)}
    />
  );
};

export default AppRedux;
