import { property } from 'underscore';
import { Redux as OverviewRedux } from 'sportsbook-overview';
import { makeGetMarketOutcomes } from 'sportsbook-markets-selector';
import { removeMarkets } from 'sportsbook-markets-duck';
import { removeOutcomes } from 'sportsbook-outcomes-duck';
import { setActiveMarketIndexSuccess } from '../action_creators';
import { getActiveMarketIndex, getConfig, getMarkets } from '../selectors';

const getMarketOutcomes = makeGetMarketOutcomes();

export default (activeMarketIndex) => (dispatch, getState) => {
  const currentActiveMarketIndex = getActiveMarketIndex(getState());

  if (activeMarketIndex === currentActiveMarketIndex) return;

  const config = getConfig(getState());
  const activeMarket = getMarkets(getState())[activeMarketIndex];
  const eventsActiveMarkets = OverviewRedux.selectors.getEventsActiveMarket(getState());

  const eventIds = Object.keys(eventsActiveMarkets).map((eventId) => parseInt(eventId, 10));
  const marketIds = Object.values(eventsActiveMarkets);
  const outcomeIds = marketIds
    .map((marketId) => getMarketOutcomes(getState(), { marketId }))
    .flat()
    .map(property('id'));

  dispatch(OverviewRedux.actionCreators.removeEventsActiveMarket(eventIds));
  dispatch(OverviewRedux.actionCreators.setEventFilters({
    eventLabels: activeMarket.eventLabels,
    expanded: config.expandedCards,
  }));
  dispatch(removeMarkets(marketIds));
  dispatch(removeOutcomes(outcomeIds));
  dispatch(setActiveMarketIndexSuccess(activeMarketIndex));
};
