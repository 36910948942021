import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useFetch } from 'bv-hooks';
import withLazyLoad from 'bv-lazy-load';
import fetchEvent from 'EventLevel/api/fetch_event';

const Scoreboard = withLazyLoad()('scoreboard');

const ScoreboardContainer = ({ eventId }) => {
  const {
    sportId,
    meetingId,
  } = useParams();

  const [event, loading] = useFetch(
    () => fetchEvent(eventId),
    [eventId],
  );

  if (loading || !event?.id) return null;

  return (
    <Scoreboard
      event={event}
      sportPathId={sportId}
      meetingId={meetingId}
    />
  );
};

ScoreboardContainer.propTypes = {
  eventId: PropTypes.number.isRequired,
};

export default ScoreboardContainer;
